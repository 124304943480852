import BEM from "tao-bem";

class Main extends BEM.Block {
	static get blockName() {
		return 'b-main';
	}

	onInit() {
		let block = $(this.elem('move-block').$el);
		let positionStart = block.offset().top;
		let positionEnd;
		
		let fMove = this.moveBlock;
		let fMark = this.markContentsTitle;

		$(window).scroll(function () {
			if($('.b-content-block').length != 0) {
				positionEnd = $('.b-content-block').last().offset().top + $('.b-content-block').last().height() - $('.b-main__move-block').height();
			} else {
				positionEnd = $('.b-main__detailText').offset().top + $('.b-main__detailText').height() - $('.b-main__move-block').height();
			}

			fMove(block, positionStart, positionEnd);
			fMark();
		});

		if($( window ).width() <= 1000) {
			$(this.elem('share').$el.detach()).appendTo(".share-mobile-container");
		}

		$('.cashoff img').each(function() {
			if(!$(this).hasClass('author')) {
				$(this).addClass('author')
			}
		})
	}

	static get elementsEvents() {
		return {
			'bookmark.click': function () {
				alert('Для добавления статьи в закладки нажмите CTRL + D');
			},
			'print.click': function () {
				window.print();
			},
			'item-anchor.click': function (elem, allElems, event) {
				event.preventDefault();
				let elemId = $(elem.$el).attr('href');
				let top = $(elemId).offset().top;
				$('body,html').animate({scrollTop: top}, 1000);
			}
		}
	}

	moveBlock(jqBlock, positionStart, positionEnd) {
		let windowTop = $(window).scrollTop();
		let moveClassName = 'b-main__move-block--move';

		if(windowTop > positionStart) {
			jqBlock.addClass(moveClassName);
		} else if(windowTop < positionStart && jqBlock.hasClass(moveClassName)) {
			jqBlock.removeClass(moveClassName);
		}

		if(windowTop > positionEnd) {
			jqBlock.fadeOut();
		} else {
			jqBlock.fadeIn()
		}
	}

	markContentsTitle() {
		let contentBlocks = $('.b-content-block');
		let activeClassName = 'b-main__item-anchor--active';

		contentBlocks.each(function (index, elem) {
			let contentsLinks = $('.b-main__content-list .b-main__item-anchor:eq(' + index +')');

			if($(window).scrollTop() >= $(elem).offset().top - 10 && $(window).scrollTop() <= ($(elem).height() + $(elem).position().top + 180)) {
				contentsLinks.addClass(activeClassName);
			} else {
				contentsLinks.removeClass(activeClassName);
			}
		})
	}
}

Main.register();

export default Main;