import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class MainServices extends BEM.Block {
	static get blockName() {
		return 'b-main-services';
	}

	onInit() {
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow29",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.b-main-service`,
						translateY: ["-150px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 800 + 200 * i;
						}
					});
				}
			}).init();
		}
	}
}

MainServices.register();

export default MainServices;