import BEM from 'tao-bem';

class MainService extends BEM.Block {
	static get blockName() {
		return 'b-main-service';
	}

	onInit() {
		let bg = this.$el.attr("data-background");
		if (bg) {
			this.elem('item').$el.css('background', bg);
			this.elem('item').$el.addClass('padding');
		} else {
			this.elem('item').$el.css('background', '#f6f8fb');
		}
	}
}

MainService.register();

export default MainService;