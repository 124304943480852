import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class DigitsBlueBg extends BEM.Block {
	static get blockName() {
		return 'b-digits-blue-bg';
	}
	onInit() {

		const it = this;
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow16",
				offset: 0,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 300 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

DigitsBlueBg.register();

export default DigitsBlueBg;