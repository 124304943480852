import BEM from 'tao-bem';
import Swiper, {Pagination, Navigation} from 'swiper';
import 'swiper/css/bundle';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";


class Experts extends BEM.Block {
	static get blockName() {
		return 'b-experts';
	}
	onInit() {
		var it = this;
		new Swiper(`.${it.block}__swiper`, {
			direction: "horizontal",
			slidesPerView: "auto",
			spaceBetween: 32,	
			modules: [Pagination, Navigation],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: `.${it.block}__swiper-next`,
				prevEl: `.${it.block}__swiper-prev`,
			},
			breakpoints: {
				640: {
					slidesPerView: 2,
					spaceBetween: 120,
				},
				800: {
					slidesPerView: 3,
					spaceBetween: 58,
				},

				1440: {
					slidesPerView: 4,
					spaceBetween: 58,
				},
			}
		});


		if ($(window).width() > 640) {
			new WOW({
				boxClass: "wow300",
				offset: 200,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}

	}
}

Experts.register();

export default Experts;