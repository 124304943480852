import BEM from 'tao-bem';

class ScrollUp extends BEM.Block {
	static get blockName() {
		return 'b-scrollup';
	}

	onInit() {
		$('.b-scrollup').click(function() {
			$("html, body").animate({
				scrollTop:0
			},1000);
		});

		$(window).scroll(function() {
			if ($(this).scrollTop() > 200) {
				$('.b-scrollup').fadeIn();
			}
			else {
				$('.b-scrollup').fadeOut();
			}
		});

	}
}

ScrollUp.register();

export default ScrollUp;