import BEM from 'tao-bem';
import 'ion-rangeslider/js/ion.rangeSlider.min.js';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input';
import Inputmask from 'inputmask';


class FormEmail extends BEM.Block {
	static get blockName() {
		return 'b-form-config-resource';
	}

	onInit() {
		const it = this;
		const form = $(`.${it.block}__form`);
		const success = $(`.${it.block}__success`);

		$(`.${it.block}__range-slider`).each(function() {
			const elem = $(this);
			elem.ionRangeSlider({
				min: 0,
				max: $(this).data('max'),
				step: $(this).data('step'),
				grid: true,
				grid_num: $(this).data('grid-count'),
				grid_snap: false,
				onChange: function (data) {
					elem.closest('.item').find(`.${it.block}__total-count`).text(data.from)
				}
			})
		});
		const utm_source = getParams()['utm_source'] ? 'source: '+ getParams()['utm_source']+', ' : '';
		const utm_medium = getParams()['utm_medium'] ? 'medium: '+ getParams()['utm_medium']+', ' : '';
		const utm_compaign = getParams()['utm_campaign'] ? 'campaign: '+ getParams()['utm_campaign']+'' : '';

		$(`.${it.block}__input-source`).val(utm_source + utm_medium + utm_compaign);

		const input = it.el.querySelector("#phone-number");
		intlTelInput(input, {
			initialCountry: "ru",
			separateDialCode: true,
		});

		function getParams() {
			let params = window
				.location
				.search
				.replace('?','')
				.split('&')
				.reduce(
					function(p,e){
						var a = e.split('=');
						p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
						return p;
					},
					{}
				);

			return params;
		}

		setTimeout(() => {
			let phone = it.elem('phone').$el;
			var mask = new Inputmask("999-999-99-99", {
				showMaskOnHover: false,
			});
			mask.mask(phone);
		}, 0)

		form.on('submit', (e) => {
			e.preventDefault();
			global.grecaptcha.execute();
			const data = form.serialize();
			$.ajax({
				type: 'POST',
				url: form.attr('action'),
				data: data,
				success: function (response) {
					const json = JSON.parse(response);

					if (json.success) {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({ 'event' : 'konfigurator_resursov'});

						form.addClass('hidden');
						success.addClass('active');
						$('html, body').animate({scrollTop: it.$el.closest('.b-resource-configurator').offset().top - $('.b-header-block').height()}, 600)
					} else {
						// Обработка ошибок
						if (json.error) {
							$('#error-message').text(json.error);
						} else {
							// Другие ошибки
							console.error('Ошибка:', json.error);
						}
					}
				}
			});
		})

		// Обработчик события input для поля номера телефона
		$('#phone-number').on('input', function() {
			$('#error-message').text('');
		});
	}
}

FormEmail.register();

export default FormEmail;