import BEM from 'tao-bem';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// import anime from "animejs/lib/anime.es.js";

class HeaderBottom extends BEM.Block {
	static get blockName() {
		return "b-header-bottom";
	}
	onInit() {
		var it = this;
		var heihgt = $(window).height();
		let bg = this.$el.attr("data-background");
		let image = $('.logo-image');
		let theme_404 = this.$el.attr("data-theme-404");
		var id = 1;
		var link = this.elems("sidebar-link").$el;
		// let theme_404 = this.$el.attr("data-theme-404");
		let idItems = it.$el.find(`.${it.block}__sidebar-content-item#${id}`);
		idItems.removeClass("hidden");

		var width = it.elem("sidebar-wrapper").$el.width();
		var windowWidth = $(window).width();
		var resaize = (windowWidth - width) / 2;

		link.css({
			'marginLeft': `-${resaize}px`,
			'paddingLeft': `${resaize - 4}px`,
		});

		//Поиск
		var search = it.elems('search').$el;
		search.click( function() {
			$(this).prev().submit();
		})

		//Загрузка картинок
		var images = it.elems('img-pic').$el;
		images.each(function() {
			var img = $(this).attr('data-image');
			$(this).css({'mask-image': `url('${img}')`, '-webkit-mask-image': `url('${img}')`});
		});

		it.elems('sidebar-link').$el.hover( function() {
			it.elems('sidebar-link').$el.removeClass('active-menu-item');

			$(this).addClass('active-menu-item');
			$(this).addClass('visible');
			var id = $(this).attr('id');
			it.elems('sidebar-content-item').$el.addClass('hidden');
			let idItems = it.$el.find(`.${it.block}__sidebar-content-item#${id}`);
			idItems.removeClass('hidden');
		}, function() {

		});
		it.elems("menu-mobile-sub-title").$el.click(function() {
			$(this).toggleClass("active");
		});

		it.elems('menu-mobile-title-item').$el.click(function() {
			$(this).parent().toggleClass("active");
		});



		it.elem('nav-toggle').$el.click(function() {
			it.$el.toggleClass('active');
			it.elem('wraper').$el.toggleClass('border');
			it.elem('help-mobile').$el.toggleClass('active');

			it.elem('nav-toggle').$el.toggleClass('active');
			it.elem('nav-toggle').$el.toggleClass('menu');
			if($(window).width() > 768) {
				it.elem('menu-mobile').$el.css({'height': heihgt - 104 });
			} else {
				it.elem('menu-mobile').$el.css({'height': heihgt - 120 });
			}

			it.elem('menu-mobile').$el.toggle(0, function() {
				const $elem = $(this);
				const $block = $(".b-header-block");
				if ($elem.hasClass("active")) {
					if (bg) {
						if ($(window).scrollTop() == 0) {
							$block.addClass("transparent");
							$block.removeClass("gray");
							if (theme_404) {
								image.attr('src', '/images/new.k2.img/logo2.svg');
							} else {
								image.attr('src', '/images/new.k2.img/logo-white.svg');
							}

						} else {
							$block.addClass("gray");
						}
						$("body").css("overflow", "auto");
						$(".b-header-bottom").removeClass("black-text");
					} else {
						$("body").css("overflow", "auto");
					}

					$elem.removeClass("active");
				} else {
					if (bg) {
						$(".b-header-bottom").addClass("black-text");
						$block.removeClass("transparent");
						image.attr('src', '/images/new.k2.img/logo-black.svg');
						$block.addClass("gray");
						$("body").css("overflow", "hidden");
					} else {
						$("body").css("overflow", "hidden");
					}
					$elem.addClass("active");
				}
			});
		});

		var scroll = new PerfectScrollbar('.b-header-bottom__menu-mobile-container', {
			wheelSpeed: 1,
			maxScrollbarLength: 110,
			swipeEasing: false,
		});

		scroll.update();

		$('.l-content').not('.b-header-bottom__search-icon').on('click', function () {
			$('.b-header-bottom__search-container').removeClass('b-header-bottom__search-container--opened');
			it.elems('help-item').$el.removeClass('hiden');
		});

		$('.b-header-bottom__search-icon').on('click', function () {
			$('.b-header-bottom__search-container').addClass('b-header-bottom__search-container--opened');
			it.elems('help-item').$el.addClass('hiden');
		});

		$('.b-header-bottom__search-icon-hidden').on('click', function () {
			$('.b-header-bottom__search-container').removeClass('b-header-bottom__search-container--opened');
			it.elems('help-item').$el.removeClass('hiden');
		})

		// anime({
		// 	targets: `.${it.block}__demo`,
		// 	translateY: ["25px", 0],
		// 	opacity: [0, 1],
		// 	easing: "linear",
		// 	delay: 1000
		// });
	}
}

HeaderBottom.register();

export default HeaderBottom;
