import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class MainDatacenters extends BEM.Block {
	static get blockName() {
		return 'b-main-datacenters';
	}

	onInit() {
		const it = this;
		const image_first = it.$el.attr('data-first');
		const image_second = it.$el.attr('data-second');
		const image_three = it.$el.attr('data-three');
		// var bg = this.elems("item").$el;

		if ($(window).width() > 1000) {
			it.$el.css("backgroundImage", `url('${image_first}')`);
		} else if($(window).width() < 1000 && $(window).width() > 400) {
			if (image_second) {
				it.$el.css("backgroundImage", `url('${image_second}')`);
			} else {
				it.$el.css("backgroundImage", `url('${image_first}')`);
			}		
		} else {
			if (image_three) {
				it.$el.css("backgroundImage", `url('${image_three}')`);
			} else if(image_second) {
				it.$el.css("backgroundImage", `url('${image_second}')`);
			} else {
				it.$el.css("backgroundImage", `url('${image_first}')`);
			}		
		}
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow26",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-150px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 200 * i;
						}
					});
				}
			}).init();
		}
	}
}

MainDatacenters.register();

export default MainDatacenters;