import BEM from 'tao-bem';
import Inputmask from 'inputmask';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

/* global dataLayer*/
// /* global carrotquest*/

class Form extends BEM.Block {
	static get blockName() {
		return 'b-form';
	}

	onInit() {
		const it = this
		let nameImage = this.$el.find(`input[name=file]`);
		nameImage.change(function() {
			let preview = it.elem('preview').$el;
			preview.text(this.files[0].name);
		});

		this.$el.find("input[type=text]").first().on('change',function() {
			if (!$(this).hasClass('event-add')) {
				if ($(this).val().length > 4) {
					$(this).addClass('event-add')
					dataLayer.push({event: "form-start", fname: it.$el.find('form').data('name'), form_name: it.$el.find('form').data('name')})
				}
			}
		});

		$('.b-form__input-clientid').val(ClientID())
		$('.b-form__input-source').val(detectBrowser())

		function detectBrowser() {
			const userAgent = navigator.userAgent.toLowerCase();

			if (userAgent.indexOf('firefox') > -1) {
				return 'Firefox';
			} else if (userAgent.indexOf('chrome') > -1) {
				return 'Chrome';
			} else if (userAgent.indexOf('safari') > -1) {
				return 'Safari';
			} else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
				return 'Opera';
			} else if (userAgent.indexOf('msie') > -1 || userAgent.indexOf('trident') > -1) {
				return 'Internet Explorer';
			} else {
				return 'Unknown';
			}
		}

		function ClientID() {
			var match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
			return (match) ? decodeURIComponent(match[1]) : false;
		}

		if (this.$el.data('lang') == 'ru') {
			let phone = it.elem('phone').$el;
			var mask = new Inputmask("+7 (999) 999-99-99", {
				showMaskOnHover: false,
			});
			mask.mask(phone);
		}

		this.elems('input').$el.on('change', function (e) {
			let trgt = e.target;
			let $trgt = $(trgt);

			if ($trgt.val() !== '') {
				$trgt.addClass('b-form__input--filled');
			} else {
				$trgt.removeClass('b-form__input--filled');
			}
		});

		$('.b-form__checkbox-popup').on('click',function (event) {
			event.preventDefault();
			$('.b-form__popup').slideToggle('slow');
		});

		if ($(window).width() > 749) {

			new WOW({
				boxClass: "wow18",
				offset: 100,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__field`,
						translateX: ["200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 600
					});
				}
			}).init();
		}
	}

	static get elementsEvents() {
		return {
			'form.submit': function (element, allElements, event) {
				event.preventDefault();

				// check recaptcha after validate
				global.grecaptcha.execute();
				let form_data = new FormData(element.el);
				var form = this.elem('form').$el;
				// var circle = this.elem('bg-circle').$el;
				var content = this.elem('content').$el;

				$(form).html('<div class="b-preloader"><div class="b-preloader__image"><img src="/images/main_redisign/pages/preloader.png" alt=""></div></div>');
				// var errors = this.elem('errors').$el;
				//  // показать индикатор загрузки
				$.ajax({
					type: 'POST',
					url: '/api/Techart/Feedback/index.php',
					data: form_data,
					processData: false,
					contentType: false,
					success: function () {
						content.addClass('hidden');
						// circle.css('display', 'none');
						try {
							dataLayer.push({'event': 'formsent','fname': form.data('name')});
							// carrotquest.identify([
							// 	{op:"update_or_create",key:"$name",value: form_data.get('subscriber_name')},
							// 	{op:"update_or_create",key:"$email",value: form_data.get('subscriber_email')},
							// 	{op:"update_or_create",key:"$phone",value: form_data.get('phone').replace('+', '')},
							// 	{op:"update_or_create",key:"Компания",value: form_data.get('company')},
							// ]);
						} catch(e) {
							return;
						}
						if ($(form).data('lang') == 'ru') {
							$(form).html('<div class="b-form__sent">Спасибо! Мы получили вашу заявку и свяжемся с вами в рабочее время.</div>');
						}
						else {
							$(form).html('<div class="b-form__sent">Thanks! We have received your application and will contact you during business hours.</div>');
						}
						$('html, body').animate({scrollTop: $(".b-form").offset().top}, 1000)
					}
				});
			},
		};
	}
}

Form.register();

export default Form;