import BEM from 'tao-bem';
import 'magnific-popup';

class Link_button extends BEM.Block {
	static get blockName() {
		return 'b-link_button';
	}

	onInit() {
		let buttonUrl = this.$el.attr('href');
		// console.log(buttonUrl.search('#'));
		if (buttonUrl == '#calculator') {
			//
		}
		else if (buttonUrl?.search('#') != -1) {
			this.$el.magnificPopup({
				type: 'inline',
				showCloseBtn: false,
				fixedContentPos: true,
				callbacks: {
					beforeOpen: function () {
						if ($(window).width() <= 1000) {
							$('html').addClass('mfp-helper');
						}
					},
					close: function () {
						$('html').removeClass('mfp-helper');
					},
				},
			});
		}

		$('.b-link_button--mfp-close').on('click', function () {
			$.magnificPopup.close();
		});

		$('.b-main_button--mfp-close').on('click', function () {
			$.magnificPopup.close();
		});

		$('.b-link_button').on('click', function(){
			var webinar = $(this).data('webinar');
			var consultation = $(this).data('consultation');

			if (consultation) {
				$('#write-to-us').find('.b-request-form__title').find('.big_h2').text("Получить консультацию");
			}
			if (webinar) {
				$('input.webinar_name').attr('value', webinar);
			}
		});
	}
}

Link_button.register();

export default Link_button;