class UtmTracker {
	// Метод для получения UTM-меток из URL
	static getUtmParamsFromUrl() {
		const urlParams = new URLSearchParams(window.location.search);
		const utmParams = {};

		for (const [param, value] of urlParams.entries()) {
			if (param.startsWith('utm_')) {
				utmParams[param] = value;
			}
		}

		return utmParams;
	}

	// Метод для получения UTM-меток из сессии
	static getUtmParams() {
		const utmParams = sessionStorage.getItem('utmParams');
		return utmParams ? JSON.parse(utmParams) : {};
	}

	// Метод для удаления UTM-меток из сессии
	static clearUtmParams() {
		sessionStorage.removeItem('utmParams');
	}

	// Метод для записи UTM-меток в сессию
	static setUtmParams(params) {
		sessionStorage.setItem('utmParams', JSON.stringify(params));
	}

	// Метод для формирования строки UTM-параметров
	static getUtmQueryString(params) {
		const queryParams = [];
		for (const property in params) {
			queryParams.push(`${property}=${encodeURIComponent(params[property])}`);
		}

		return queryParams.join('&');
	}
}

export default UtmTracker;