import BEM from "tao-bem";
import Swiper from "swiper/bundle";
import anime from "animejs/lib/anime.es.js";

import "swiper/css/bundle";
import "jquery-match-height";

class MainSlider extends BEM.Block {
	static get blockName() {
		return "b-main-slider";
	}

	onInit() {
		const it = this;
		var bg = this.elems("item").$el;

		if ($(window).width() > 1000) {
			bg.each(function() {
				var first = $(this).attr("data-first");
				$(this).css("backgroundImage", `url('${first}')`);
			});
		} else if($(window).width() < 1000 && $(window).width() > 400) {
			bg.each(function() {
				var second = $(this).attr("data-second");
				if (second) {
					$(this).css("backgroundImage", `url('${second}')`);
				} else {
					var first = $(this).attr("data-first");
					$(this).css("backgroundImage", `url('${first}')`);
				}
			});
		} else {
			bg.each(function() {
				var three = $(this).attr("data-three");
				var second = $(this).attr("data-second");
				if (three) {
					$(this).css("backgroundImage", `url('${three}')`);
				} else if (second) {
					$(this).css("backgroundImage", `url('${second}')`);
				} else {
					var first = $(this).attr("data-first");
					$(this).css("backgroundImage", `url('${first}')`);
				}
			});
		}

		if ($(window).width() > 749) {
			// anime({
			// 	targets: `.${it.block}__circles-bg circle`,
			// 	easing: "easeInOutSine",
			// 	loop: true,
			// 	keyframes: [
			// 		{
			// 			strokeDashoffset: [-1500,anime.setDashoffset, -1500],
			// 			duration: 4000,
			// 			delay: 1000,
			// 			endDelay:2000
			// 		},
			// 	],
			// });

			// anime({
			// 	targets: `.${it.block}__circles-bg path`,
			// 	easing: "easeInOutSine",
			// 	loop: true,

			// 	keyframes: [
			// 		{
			// 			strokeDashoffset: [anime.setDashoffset, 0],
			// 			duration: 1000,
			// 			delay: 1000
			// 		},
			// 		{
			// 			strokeDashoffset: [0, -320],
			// 			duration: 1000,
			// 			delay: 2500
			// 		}
			// 	],
			// });

			setTimeout(function() {
				anime({
					targets: `.${it.block} .big_h1.first`,
					translateY: ["25px", 0],
					opacity: [0, 1],
					easing: "linear",
					delay: 600
				});

				anime({
					targets: `.${it.block}__desc.first`,
					translateY: ["25px", 0],
					opacity: [0, 1],
					easing: "linear",
					delay: 450
				});

				anime({
					targets: `.${it.block}__button.first`,
					translateY: ["25px", 0],
					opacity: [0, 1],
					easing: "linear",
					delay: 600
				});
				anime({
					targets: `.${it.block}__bg-black`,
					opacity: [1, 0],
					easing: "linear",
					duration: 2500,
				});
			}, 100);

		}

		new Swiper(".mainSwiper", {
			loop: true,
			effect: "fade",
			autoplay: {
				delay: 8000
			},
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true
			},

			on: {

				slideChange: function() {
					if ($(window).width() > 749) {
						anime({
							targets: `.${it.block}__item`,
							backgroundPositionY: ["0", "-50px"],
							easing: "linear",
							duration: 3500,
							delay: 2500,

						});

						// anime({
						// 	targets: `.${it.block} .big_h1`,
						// 	translateY: ["25px", 0],
						// 	opacity: [0, 1],
						// 	easing: "linear",
						// 	delay: 3000
						// });

						// anime({
						// 	targets: `.${it.block}__desc`,
						// 	translateY: ["25px", 0],
						// 	opacity: [0, 1],
						// 	easing: "linear",
						// 	delay: 3250
						// });

						// anime({
						// 	targets: `.${it.block}__button`,
						// 	translateY: ["25px", 0],
						// 	opacity: [0, 1],
						// 	easing: "linear",
						// 	delay: 3500
						// });
					}
				}
			}
		});
	}
}

MainSlider.register();

export default MainSlider;
