import BEM from 'tao-bem';

class CrocForm extends BEM.Block {
	static get blockName() {
		return 'b-croc-form';
	}
}

CrocForm.register();

export default CrocForm;