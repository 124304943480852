import BEM from 'tao-bem';
import Parallax from 'parallax-js';
import anime from "animejs/lib/anime.es.js";

class ImageBlock extends BEM.Block {
	static get blockName() {
		return 'b-image-block';
	}
	
	onInit() {
		const it = this;
		var first = this.$el.attr('data-image-first');
		var second = this.$el.attr('data-image-second');
		var three = this.$el.attr('data-image-three');
		// var container = it.elem('image-container').$el;
		
		// Определение высоты
		
		var heightBlock = it.$el.height();

		if ($(window).width() < 1000 && $(window).width() > 400) {
			if(second) {
				it.elem('image').$el.attr('src', second);
			} else {
				it.elem('image').$el.attr('src', first);	
			}
		}
		else if($(window).width() > 1000) {
			it.elem('image').$el.attr('src', first);
		} else {
			if(three) {
				it.elem('image').$el.attr('src', three);
			} else if(second) {
				it.elem('image').$el.attr('src', second);
			} else {
				it.elem('image').$el.attr('src', first);
			}
		}
		var heightContent = it.elem('wrapper').$el.height();
		// Проверка высоты
		if (heightContent >= heightBlock) {
			it.elem('image').$el.css('height', heightContent + 100);
		}

		window.addEventListener('resize', () => {
			resize();
		});
		resize();
		let height;
		function resize() {
			if ($(window).width() < 400) {
				height = $('.b-image-block__wrapper').height() + 60
				it.$el.css('height', `${height}px`)
			} else {
				if ($(window).width() < 1600) {
					if ($(window).width() < 640) {
						height = $('.b-image-block__wrapper').height() + 60
					} else {
						height = $('.b-image-block__wrapper').height() + 120
					}
					
					it.$el.css('height', `${height}px`)
				} else {
					it.$el.css('height', `900px`)
				}
			}
		}

		if ($(window).width() > 1000) {
			// var width = $(window).width();
			// container.css('width', width+100);
			
			it.$el.css('overflow', 'hidden')
			var scene = document.getElementById('scene');
			var parallaxInstance = new Parallax(scene, {
				relativeInput: true
			});
			parallaxInstance.friction(0.08, 0.08);
			it.$el.hover(function() {
				parallaxInstance.enable();
			}, function() {
				parallaxInstance.disable();
			});
			// container.css('height', heightContent);
		}

		if ($(window).width() > 749) {
			anime({
				targets: `.b-main-slider__circles-bg circle`,
				easing: "easeInOutSine",
				loop: true,
				keyframes: [
					{
						strokeDashoffset: [-1500,anime.setDashoffset, -1500],
						duration: 4000,
						delay: 1000,
						endDelay:2000
					},
				],
			});

			anime({
				targets: `.b-main-slider__circles-bg path`,
				easing: "easeInOutSine",
				loop: true,

				keyframes: [
					{
						strokeDashoffset: [anime.setDashoffset, 0],
						duration: 1000,
						delay: 1000
					},
					{
						strokeDashoffset: [0, -320],
						duration: 1000,
						delay: 2500
					}
				],
			});
		}
	}
}

ImageBlock.register();

export default ImageBlock;