import BEM from 'tao-bem';

class Podcast extends BEM.Block {
	static get blockName() {
		return 'b-podcast';
	}
	onInit() {

	}
}

Podcast.register();

export default Podcast;