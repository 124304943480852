import BEM from 'tao-bem';

class Page404 extends BEM.Block {
	static get blockName() {
		return 'b-page-404';
	}

	// /*global Gradient*/

	onInit() {
		// Gradient.graphic();
		var it = this;
		// var big = it.$el.attr('data-big');
		// var medium = it.$el.attr('data-medium');
		// var huge = it.$el.attr('data-huge');
		// var small = it.$el.attr('data-small');
		var search = it.elem('search-icon').$el;
		// if($(window).width() > 768 && $(window).width() < 1440) {
		// 	it.$el.css('backgroundImage', `url('${big}')`);
		// } else if($(window).width() > 360 && $(window).width() < 768) {
		// 	it.$el.css('backgroundImage', `url('${medium}')`);
		// } else if($(window).width() > 1440){
		// 	it.$el.css('backgroundImage', `url('${huge}')`);
		// } else {
		// 	it.$el.css('backgroundImage', `url('${small}')`);
		// }

		
		search.click( function() {
			$(this).prev().submit();
		})
	}
}

Page404.register();

export default Page404;