import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";
import 'jquery-match-height';
import Swiper , { Pagination } from 'swiper';
import 'swiper/css/bundle';

class BlockServices extends BEM.Block {
	static get blockName() {
		return 'b-block-logos';
	}

	onInit() {
		const it = this;

		window.swiper = undefined; 

		it.setSwiper();

		$(window).resize(function () {
			it.setSwiper();
		});
			
		if ($(window).width() > 1000) {
			new WOW({
				boxClass: "wow44",
				offset: 150,
				mobile: true,
				live: false,
				callback: function(elem) {
					const items = $(elem).find(`.${it.block}__item`);
					items.each(function(index) {
						anime({
							targets: this,
							translateY: ["-200px", "0"],
							opacity: [0, 1],
							easing: "easeInOutCubic",
							delay: function() {
								return 600 + 150 * index;
							}
						});
					});			
				}
			}).init();
		}
	}

	setSwiper() {
		if (window.innerWidth > 640) {
			$('.b-block-logos__items').removeClass('swiper-wrapper');
		} else {
			$('.b-block-logos__items').addClass('swiper-wrapper');
		}

		let breakpoint = window.matchMedia('(min-width: 640px)');
		if (breakpoint.matches) {
			if (window.swiper !== undefined && !window.swiper.destroyed) {
				window.swiper.destroy(true, true);
			}
		} else {
			if (window.swiper === undefined || window.swiper.destroyed) {
				window.swiper = new Swiper(this.$elem('swiper').get(0), {
					spaceBetween: 32,
					slidesPerView: 1,
					modules: [Pagination],
					pagination: {
						el: this.$elem('pagination').get(0),
						clickable: true,
					},
				});

				setTimeout(function() {
					window.swiper.update();
					window.swiper.updateSize();
					window.swiper.updateSlides();
				}, 500);
			}
		}
	} 
}


BlockServices.register();

export default BlockServices;