import BEM from "tao-bem";

class SectionTop extends BEM.Block {
	static get blockName() {
		return 'b-section-top';
	}
}

SectionTop.register();

export default SectionTop;