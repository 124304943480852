import BEM from 'tao-bem';

class Footer extends BEM.Block {
	static get blockName() {
		return 'b-footer';
	}

	onInit() {
		var it = this;
		
		it.elems('item').$el.click(function() {
			// e.preventDefault();
			$(this).toggleClass('active');
		});

		it.elems('mobile-item').$el.click(function() {
			$(this).toggleClass('active');
		});

		it.elems('image-list').$el.click(function() {
			$(this).parent().parent().toggleClass('active');
			$(this).addClass('active');
		});

	}
}

Footer.register();

export default Footer;