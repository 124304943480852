import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class QuestionsList extends BEM.Block {
	static get blockName() {
		return 'b-questions-list';
	}
	
	onInit() {
		var it = this;
		
		it.elems('item').$el.click(function() {
			$(this).toggleClass('active');
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow36",
				offset: 160,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateX: ["-200px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

QuestionsList.register();

export default QuestionsList;