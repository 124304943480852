import BEM from "tao-bem";
import anime from 'animejs/lib/anime.es.js';
import WOW from "wow.js";

class CustomersDetail extends BEM.Block {
	static get blockName() {
		return 'b-customers-detail';
	}

	onInit() {
		var it = this;
		var path = anime.path('.pattern-svg');


		new WOW({
			boxClass: "wow",
			offset: 0,
			mobile: true,
			live: true,
			callback: function() {

				anime({
					targets: `.${it.block}__orange-circle`,
					translateX: path('x'),
					translateY: path('y'),
					rotate: path('angle'),
					easing: 'linear',
					duration: 15000,
					loop: true
				});

				anime({
					targets: `.${it.block}__orange-circle`,
					opacity:[0,1],

					delay: 1500,

				});

				anime({
					targets: `.${it.block}__img`,
					scale:[0,1],

					easing: 'linear',
					duration: 1500,

				});





				anime({
					targets: ".pattern-svg__little-circle",
					translateX: {
						value: '-5%',
					},
					translateY: {
						value: '-5%',
					},
					scale: {
						value: 1.1,
					},

					direction: 'alternate',
					easing: 'linear',
					duration: 1500,
					loop: true
				});
			}
		}).init();
	}
}

CustomersDetail.register();

export default CustomersDetail;
