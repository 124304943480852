import BEM from "tao-bem";

class ContentBlock extends BEM.Block {
	static get blockName() {
		return 'b-content-block';
	}

	onInit() {
		if ($(window).width() < '1280') {
			this.insertToContentText();
			this.insertToContentLinksBlocks();
		} else {
			this.hideInsertions;
		}

		$('.cashoff img').each(function() {
			if(!$(this).hasClass('author')) {
				$(this).addClass('author')
			}
		})
	}

	insertToContentText() {
		if ($(this.elem('textInsertion')).length > 0) {
			let fromTextInsertion = $(this.elem('textInsertion').$el).clone();
			$(this.$el).find('.insertion-text').html(fromTextInsertion);
		}
	}

	insertToContentLinksBlocks() {
		let fromLinksBlocks = $(this.$el).find('.b-links-block');
		let whereLinksBlockFirst = $(this.$el).find('.insertion_links_first');
		let whereLinksBlockSecond = $(this.$el).find('.insertion_links_second');
		switch(fromLinksBlocks.length) {
			case 1:
				if (whereLinksBlockFirst.length !== 0)
					whereLinksBlockFirst.html($(fromLinksBlocks[0]).clone());
				break;
			case 2:
				if (whereLinksBlockFirst.length !== 0)
					whereLinksBlockFirst.html($(fromLinksBlocks[0]).clone());
				if (whereLinksBlockSecond.length !== 0)
					whereLinksBlockSecond.html($(fromLinksBlocks[1]).clone());
				break;
		}
	}

	hideInsertions() {
		$(this.elem('textInsertion').$el).hide();
		$(this.$el).find('.insertion_links_first').hide();
		$(this.$el).find('.insertion_links_second').hide();
	}
}

ContentBlock.register();

export default ContentBlock;