import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class BlockTypeDisk extends BEM.Block {
	static get blockName() {
		return 'b-block-type-disk';
	}
	onInit() {

		const it = this;
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow44",
				offset: 200,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

BlockTypeDisk.register();

export default BlockTypeDisk;