import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class BreedingBlockServices extends BEM.Block {
	static get blockName() {
		return 'b-breeding-block-services';
	}

	onInit() {
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow7",
				offset: 100,
				mobile: true,
				live: false,
				callback: function(elem) {
					anime({
						targets: elem,
						translateY: ["-200px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function() {
							return 150;
						}
					});
				}
			}).init();
		}
	}
}

BreedingBlockServices.register();

export default BreedingBlockServices;