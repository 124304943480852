import BEM from "tao-bem";
import "slick-carousel";


class Slider extends BEM.Block {
	static get blockName() {
		return 'b-slider';
	}

	static get elementsEvents() {
		return {
			'arrow.click': function (elem) {
				if (elem.hasMod('left')) {
					this.changeCurrent(false);
				} else {
					this.changeCurrent();
				}
			}
		}
	}

	onInit() {
		let total = this.elems('slide').$el.length;
		let totalEl = this.elems('count').byMod('total').$el;
		totalEl.text(this.addZero(total));
		this.initSlick();
	}

	initSlick() {
		$('.b-slider__content-slider').slick({
			// infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: $('#content-slider-arrow-l'),
			nextArrow: $('#content-slider-arrow-r'),
		});
	}

	changeCurrent(next = true) {
		let currentEl = this.elems('count').byMod('current').$el;
		let currentValue = parseInt(currentEl.text());
		let totalEl = this.elems('count').byMod('total').$el;
		let total = this.elems('slide').$el.length;
		totalEl.text(this.addZero(total));

		if(next) {
			if (currentValue != total) {
				currentEl.text(this.addZero(++currentValue));
			} else {
				currentEl.text('01');
			}
		} else {
			if (currentValue != 1) {
				currentEl.text(this.addZero(--currentValue));
			} else {
				currentEl.text(this.addZero(total));
			}
		}
	}

	addZero(value) {
		if (value < 10)
			return '0' + value.toString();
		else {
			return value.toString();
		}
	}
}

Slider.register();

export default Slider;

