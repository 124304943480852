import BEM from 'tao-bem';
import Swiper, { Grid, Navigation, Pagination} from 'swiper';
import 'swiper/css/bundle';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class Clients extends BEM.Block {
	static get blockName() {
		return 'b-clients';
	}
	onInit() {
		const it = this;
		new Swiper(".clientsSwiper", {
			slidesPerView: 2,
			spaceBetween: 32,
			modules: [Grid, Pagination, Navigation],
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev",
			},
			breakpoints: {
				320: {
					slidesPerView: 2,
					spaceBetween: 24,
					slidesPerGroup: 2,
					grid: {
						rows: 1,
						fill: 'row',
					},
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 32,
					slidesPerGroup: 2,
					grid: {
						rows: 1,
					},
				},
				1000: {
					slidesPerView: 3,
					spaceBetween: 32,
					slidesPerGroup: 1,
					grid: {
						rows: 1,
					},
				}
			}
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow11",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-100px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

Clients.register();

export default Clients;