import BEM from "tao-bem";
import anime from "animejs/lib/anime.es.js";
// import Scrollbar from 'smooth-scrollbar/dist/smooth-scrollbar';

class HeaderTop extends BEM.Block {
	static get blockName() {
		return 'b-header-top';
	}

	onInit() {
		const it = this;

		

		$('.l-content').not('.b-header-top__search-icon').on('click', function () {
			$('.b-header-top__search-container').removeClass('b-header-top__search-container--opened');
			$('.b-header-top__help').removeClass('hidden');
			$('.b-header-top__container').removeClass('hidden');
			
		});
		$('.b-header-top__search-icon').on('click', function () {
			$('.b-header-top__search-container').addClass('b-header-top__search-container--opened');
			$('.b-header-top__help').addClass('hidden');
			$('.b-header-top__container').addClass('hidden');
		});

		$('.b-header-top__search-icon-hidden').on('click', function () {
			$('.b-header-top__search-container').removeClass('b-header-top__search-container--opened');
			$('.b-header-top__help').removeClass('hidden');
			$('.b-header-top__container').removeClass('hidden');
		})




		// anime({
		// 	targets: `.${it.block}__feedback div`,
		// 	translateY: ["25px", 0],
		// 	opacity: [0, 1],
		// 	easing: "linear",
		// 	delay: function(el, i) {
		// 		return 900 + 150 * i;
		// 	}
		// });

		// anime({
		// 	targets: `.${it.block}__help a`,
		// 	translateY: ["25px", 0],
		// 	opacity: [0, 1],
		// 	easing: "linear",
		// 	delay: function(el, i) {
		// 		return 1000 + 150 * i;
		// 	}
		// });
		// anime({
		// 	targets: `.${it.block}__help-item `,
		// 	translateY: ["25px", 0],
		// 	opacity: [0, 1],
		// 	easing: "linear",
		// 	delay: 1300
		// });

		anime({
			targets: `.${it.block}__bottom-line`,

			width: ['0%', '100%'],
			easing: "linear",
			delay: 1000
		});
		// Scrollbar.init($('body')[0], {
		// 	thumbMinSize: 200
		// });
	}
}

HeaderTop.register();

export default HeaderTop;