import BEM from 'tao-bem';
import Swiper, {Pagination, Navigation} from 'swiper';
import 'swiper/css/bundle';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class CrocNews extends BEM.Block {
	static get blockName() {
		return 'b-certificates';
	}

	onInit() {
		var it = this;

		new Swiper(`.certificatesSwiper`, {
			slidesPerView: 1,
			spaceBetween: 0,
			modules: [Pagination, Navigation],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: `.${it.block}__swiper-next`,
				prevEl: `.${it.block}__swiper-prev`,
			},

			breakpoints: {
				320: {
					slidesPerView: "auto",
					spaceBetween: 0,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 94,
				},
				// when window width is >= 1000px
				1000: {
					slidesPerView: 4,
					spaceBetween: 32,
				}
			}
		});

		if ($(window).width() > 749) {

			new WOW({
				boxClass: "wow9",
				offset: 200,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.b-certificat`,
						translateY: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

CrocNews.register();

export default CrocNews;