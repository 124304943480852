import BEM from 'tao-bem';

class Header extends BEM.Block {
	static get blockName() {
		return 'b-header';
	}
	onInit() {
		var bg = this.$el.attr('data-background');
		var bgMiddle = this.$el.attr('data-background-middle');
		var bgSmall = this.$el.attr('data-background-small');
		if (window.innerWidth > 1000) {
			this.$el.css('backgroundImage', `url('${bg}')`);
		} else if (window.innerWidth > 768) {
			this.$el.css('backgroundImage', `url('${bgMiddle}')`);
		} else {
			this.$el.css('backgroundImage', `url('${bgSmall}')`);
		}
		
	}
}

Header.register();

export default Header;