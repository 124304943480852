import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class PictureBlock extends BEM.Block {
	static get blockName() {
		return 'b-picture-block';
	}
	onInit() {
		const it = this;
		var img = this.elem('img').$el;
		var image = this.elem('image').$el;
		var first = image.attr('data-first');
		var second = image.attr('data-second');
		var three = image.attr('data-three');
		var width = $(window).outerWidth();

		var isset = false;
		if (three && width <= 640) {
			img.attr("src", three);
			isset = true;
		}		
		else if (second && width > 640 && width < 999) {
			img.attr("src", second);
			isset = true;
		} 

		if (!isset && first) {
			img.attr("src", first);
		}

		// Fancybox.getInstance().getSlide().Panzoom.toggleZoom();

		Fancybox.bind("[data-fancybox]", {
			Image: {
				zoom: true,
			},
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow35",
				offset: 160,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__image-block`,
						translateX: ["-200px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 600,
					});
				}
			}).init();
		}
	}
}

PictureBlock.register();

export default PictureBlock;