import BEM from "tao-bem";

class Counters extends BEM.Block {
	static get blockName() {
		return 'b-counters';
	}

	static get elementsEvents() {
		return {
			'article-like-clickable.click': function (element) {
				var isLikedCookie = document.cookie;
				var results = isLikedCookie.match ( '(^|;)liked=([^;]*)(;|$)' );

				if ( !results ) {
					var likeElem = $(element.$el);
					var articleID = likeElem.data('article_id');
					$.ajax({
						type: 'POST',
						url: '/api/Techart/Blog/likes.php',
						data: {
							id: articleID
						},
						success: function (responce) {
							var likesCounterHeader = $('.b-article-header .b-counters__property--likes-counter');
							likeElem.fadeOut("slow", function () {
								likesCounterHeader.text(responce);
								likeElem.text(responce);
							});
							likeElem.fadeIn();
						},
						error: function (xhr, str) {
							alert('Возникла ошибка: ' + xhr.responseCode + ' ' + str);
						}
					});
					var cookieDate = new Date();
					cookieDate.setFullYear(cookieDate.getFullYear() + 1);
					document.cookie = "liked=true; expires=" + cookieDate;
					$(element.$el).hide();
				}
			},
		};
	}
}

Counters.register();

export default Counters;