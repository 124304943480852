import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class ResourceConfigurator extends BEM.Block {
	static get blockName() {
		return 'b-resource-configurator';
	}

	onInit() {
		const it = this;
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow37",
				offset: 160,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__calc`,
						translateX: ["-200px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 600,
					});
				}
			}).init();
		}
	}
}

ResourceConfigurator.register();

export default ResourceConfigurator;