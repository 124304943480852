import BEM from 'tao-bem';


class TitleAndText extends BEM.Block {
	static get blockName() {
		return 'b-title-and-text';
	}
	onInit() {
		//
	}
}

TitleAndText.register();

export default TitleAndText;