import BEM from 'tao-bem';

class HeaderBlock extends BEM.Block {
	static get blockName() {
		return 'b-header-block';
	}
	onInit() {
		var it = this;

		let bg = this.$el.attr("data-background");
		let theme_404 = this.$el.attr("data-theme-404");
		let image = $('.logo-image');

		if (!bg) {
			this.$el.css({ 'backgroundColor': '#f6f8fb' });
			$('.b-header-bottom__demo').removeClass('c-button-blue-dark');
			$('.b-header-bottom__demo').addClass('c-button-blue-light');
		}
		else {
			$('.b-header-top').addClass('white-text');
			$('.b-header-bottom').addClass('white-text');
			this.$el.addClass('border');
		}
		const $block = $('.b-header-block');
		$('.b-header-bottom__navigation-link').hover(function () {
			if (bg) {
				$block.addClass('gray');
				$block.removeClass('transparent');
				if (theme_404) {
					$('.b-header-bottom__demo').addClass('c-button-blue-light');
					$('.b-header-bottom__demo').removeClass('c-button-white');
					image.attr('src', '/images/new.k2.img/logo-black.svg');
				} else {
					$('.b-header-bottom__demo').removeClass('c-button-blue-dark');
					$('.b-header-bottom__demo').addClass('c-button-blue-light');
					image.attr('src', '/images/new.k2.img/logo-black.svg');
				}
				$('.b-header-top').removeClass('white-text');
				$('.b-header-bottom').removeClass('white-text');
			}
		}, function () {
			if (bg && $(window).scrollTop() == 0) {
				$block.removeClass('gray');
				$block.addClass('transparent');
				it.$el.css({'backgroundColor': 'transparent'});
				if (theme_404) {
					$('.b-header-bottom__demo').addClass('c-button-white');
					$('.b-header-bottom__demo').removeClass('c-button-blue-light');
					image.attr('src', '/images/new.k2.img/logo2.svg');
				} else {
					$('.b-header-bottom__demo').addClass('c-button-blue-dark');
					$('.b-header-bottom__demo').removeClass('c-button-blue-light');
					image.attr('src', '/images/new.k2.img/logo-white.svg');
				}

				$('.b-header-top').addClass('white-text');
				$('.b-header-bottom').addClass('white-text');
			}
		});

		let countScroll = $(window).scrollTop();

		$(window).on('scroll', function () {
			if (countScroll > $(window).scrollTop()) {

				if($(window).scrollTop() <= 1000) {
					it.$el.removeClass('active');
					setTimeout(function() {
						it.$el.removeClass('transition');
					}, 50);
					it.$el.removeClass('fixed');
					if (theme_404) {
						$('.b-header-bottom__demo').addClass('c-button-white');
						$('.b-header-bottom__demo').removeClass('c-button-blue-dark');
						image.attr('src', '/images/new.k2.img/logo2.svg');
					} else {
						$('.b-header-bottom__demo').addClass('c-button-blue-dark');
						$('.b-header-bottom__demo').removeClass('c-button-blue-light');

						if (!bg) {
							image.attr('src', '/images/new.k2.img/logo-black.svg');
						} else {
							image.attr('src', '/images/new.k2.img/logo-white.svg');
						}
					}

					it.$el.removeClass('gray');
					if (!bg) {
						$('.b-header-top').removeClass('white-text');
						$('.b-header-bottom').removeClass('white-text');
					} else {
						$('.b-header-top').addClass('white-text');
						$('.b-header-bottom').addClass('white-text');
					}
				} else {
					it.$el.addClass('gray');
					it.$el.addClass('active');
					setTimeout(function() {
						it.$el.addClass('transition');
					}, 50);
					it.$el.addClass('fixed');

					image.attr('src', '/images/new.k2.img/logo-black.svg');
					$('.b-header-bottom__demo').removeClass('c-button-blue-dark');
					$('.b-header-bottom__demo').addClass('c-button-blue-light');
					$('.b-header-top').removeClass('white-text');
					$('.b-header-bottom').removeClass('white-text');
				}
			}
			else {

				// image.attr('src', '/images/new.k2.img/logo.svg');
				if (!bg) {
					it.$el.css({ 'backgroundColor': '#f6f8fb' });
					$('.b-header-bottom__demo').removeClass('c-button-blue-dark');
					$('.b-header-bottom__demo').addClass('c-button-blue-light');
					$('.b-header-top').removeClass('white-text');
					$('.b-header-bottom').removeClass('white-text');
				}
				else {
					$block.removeClass('gray');
					$block.addClass('transparent');

					if (theme_404) {
						if ($(window).scrollTop() > 140) {
							image.attr('src', '/images/new.k2.img/logo-black.svg');
							$('.b-header-bottom__demo').addClass('c-button-blue-dark');
							$('.b-header-bottom__demo').removeClass('c-button-white');
						}
					} else {
						if ($(window).scrollTop() > 140) {
							image.attr('src', '/images/new.k2.img/logo-black.svg');
						} else {
							image.attr('src', '/images/new.k2.img/logo-white.svg');
						}
						$('.b-header-bottom__demo').addClass('c-button-blue-dark');
						$('.b-header-bottom__demo').removeClass('c-button-blue-light');
					}
					$('.b-header-top').addClass('white-text');
					$('.b-header-bottom').addClass('white-text');
				}
				it.$el.removeClass('active');
				setTimeout(function() {
					it.$el.removeClass('transition');
				}, 50);
				// it.$el.removeClass('fixed');

			}
			countScroll = $(window).scrollTop();
		});

	}
}

HeaderBlock.register();

export default HeaderBlock;