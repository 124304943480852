import BEM from 'tao-bem';

class ExpertDetail extends BEM.Block {
	static get blockName() {
		return 'b-expert-detail';
	}

	onInit() {
		$('.b-expert-detail__more-description').on('click', function () {
			$('.b-expert-detail__description-long').slideDown('slow');

			$(this).animate({
				opacity: 'hide',
				height: 'hide',
			}, 500, function () {
				$(this).remove();
			});
		});
	}
}

ExpertDetail.register();

export default ExpertDetail;