import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class KeyFeature extends BEM.Block {
	static get blockName() {
		return 'b-key-feature';
	}

	onInit() {
		const it = this;
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow23",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item.left`,
						translateX: ["-100px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 300
					});
					anime({
						targets: `.${it.block}__item.right`,
						translateX: ["100px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 300
					});
				}
			}).init();
		}
	}
}

KeyFeature.register();

export default KeyFeature;