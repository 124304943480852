import BEM from "tao-bem";

class ArrowsAnimate extends BEM.Block {
	static get blockName() {
		return 'b-arrows-animate';
	}
}

ArrowsAnimate.register();

export default ArrowsAnimate;