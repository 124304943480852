import BEM from "tao-bem";
import Inputmask from 'inputmask';

/* global dataLayer*/
// /* global carrotquest*/

class RequestForm extends BEM.Block {
	static get blockName() {
		return 'b-request-form';
	}

	onInit() {
		const it = this;

		if (this.elem('services-name')) {
			var servicesButton = this.elem('services-name').$el;
		}
		this.$el.find("input[name=subscriber_name]").on('change',function() {
			if (!$(this).hasClass('event-add')) {
				if ($(this).val().length > 4) {
					$(this).addClass('event-add');
					dataLayer.push({event: "form-start", fname: it.$el.find('form').data('name'), form_name: it.$el.find('form').data('name')})
				}
			}
		});

		var serviceslist;

		if (this.elem('services-list')) {
			serviceslist = this.elem('services-list').$el;
		}

		if (this.elem('services-lists')) {
			serviceslist = this.elem('services-lists').$el;
		}

		if (this.$el.data('lang') == 'ru') {
			let phone = this.elem('phone').$el;
			var mask = new Inputmask("+7 (999) 999-99-99", {
				showMaskOnHover: false,
				lazy: false
			});
			mask.mask(phone);
		}


		$('.b-request-form__input-source').val(getParams()['utm_source'] ? getParams()['utm_source'] : '')
		$('.b-request-form__input-clientid').val(ClientID())
		$('.b-request-form__input-sheet-name').val($('.sheet-name').text())

		if (this.elem('services-name')) {
			servicesButton.on('click', function () {
				serviceslist.slideToggle('500');
				servicesButton.toggleClass('b-request-form__services-name--open');
			});
		}

		function ClientID() {
			var match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
			return (match) ? decodeURIComponent(match[1]) : false;
		}

		function getParams() {
			let params = window
				.location
				.search
				.replace('?','')
				.split('&')
				.reduce(
					function(p,e){
						var a = e.split('=');
						p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
						return p;
					},
					{}
				);

			return params;
		}

		this.elems('input').$el.on('change', function (e) {
			let trgt = e.target;
			let $trgt = $(trgt);

			if ($trgt.val() !== '') {
				$trgt.addClass('b-request-form__input--filled');
			} else {
				$trgt.removeClass('b-request-form__input--filled');
			}
		});

		this.elems('textarea').$el.on('change', function (e) {
			let trgt = e.target;
			let $trgt = $(trgt);

			if ($trgt.val() !== '') {
				$trgt.addClass('b-request-form__input--filled');
			} else {
				$trgt.removeClass('b-request-form__input--filled');
			}
		});

		$('.b-main_button__link').click(function() {
			if ($(this).data('text')) {
				$('.b-request-form__field.quetion[id="text"]').removeClass('hidden');
				$('.b-request-form__field.quetion[id="text"]').find('.b-request-form__services-name').html($(this).data('text'));
			}
		});

		$('.b-main_button--mfp-close').on('click', function () {
			$('.b-request-form__field.quetion[id="text"]').addClass('hidden');
		});

		$(document).mouseup( function(e){
			var div = $('.b-request-form__field--selector');
			if ( !div.is(e.target)
				&& div.has(e.target).length === 0 ) {
				serviceslist.slideUp();
				servicesButton.removeClass('b-request-form__services-name--open');
			}
		});

		it.$el.find('.b-request-form__input').on('input', function(){
			if ($(this).val() != '') {
				$(this).removeClass('empty');
				if ($(this).hasClass('.b-request-form__email')) {
					emailTest();
				}
				if ($(this).hasClass('.b-request-form__phone')) {
					phoneTest();
				}
			} else {
				$(this).addClass('empty');
			}
		});

		it.$el.find('.b-request-form__textarea').on('input', function(){
			if (it.$el.find('.b-request-form__textarea').val() != "") {
				it.$el.find('.b-request-form__textarea').removeClass('empty');
			}
		});

		it.$el.find('.b-request-form__checkbox.politic').parent().find('.b-request-form__checkbox-label').click(function() {
			if (it.$el.find('.b-request-form__checkbox.politic').is(":checked")) {
				it.$el.find('.b-request-form__checkbox.politic').parent().find('.b-request-form__checkbox-label').removeClass('empty');
			} else {
				it.$el.find('.b-request-form__checkbox.politic').parent().find('.b-request-form__checkbox-label').removeClass('empty');
			}
		})

		function emailTest() {
			var emailTest = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}$/;
			var email = it.$el.find('.b-request-form__email');
			if (!emailTest.test(email.val())) {
				email.addClass('empty')
			} else {
				email.removeClass('empty')
			}
		}

		function phoneTest() {
			var phoneTest = /^\+7 [(](\d{3})[)] (\d{3})[-](\d{2})[-](\d{2})$/;
			var phone = it.$el.find('.b-request-form__phone');
			if (!phoneTest.test(phone.val())) {
				phone.addClass('empty')
			} else {
				phone.removeClass('empty')
			}
		}

		it.$el.find('.b-request-form__submit-button').click(function() {

			emailTest();
			phoneTest();

			it.$el.find('.b-request-form__input').each(function(){
				if ($(this).val() == '') {
					$(this).addClass('empty');
				}
			});

			if (it.$el.find('.b-request-form__textarea').val() == "") {
				it.$el.find('.b-request-form__textarea').addClass('empty');
			} else {
				it.$el.find('.b-request-form__textarea').removeClass('empty');
			}




			if (it.$el.find('.b-request-form__services-list').find('.b-request-form__checkbox:checked').length < 1) {
				it.$el.find('.b-request-form__field--selector').addClass('b-request-form__field--empty');
			}

			if (it.$el.find('.b-request-form__checkbox.politic').is(":checked")) {
				it.$el.find('.b-request-form__checkbox.politic').parent().find('.b-request-form__checkbox-label').removeClass('empty');
			} else {
				it.$el.find('.b-request-form__checkbox.politic').parent().find('.b-request-form__checkbox-label').addClass('empty');
			}
		});
	}

	static get elementsEvents() {
		return {
			'form.submit': function (element, allElements, event) {
				event.preventDefault();
				var er = 0;

				// должен быть выбран способ связи
				if (this.$el.find(this.$elem('services-list')).length) {
					if (!this.checkCommunicationMethods()) {
						this.elems('field').byMod('selector').addMod('empty');
						er = 1;
					}
				}

				// на русском языке телефон должен соответствовать маске +7 (999) 999-99-99
				let phone = this.elem('phone').$el.val();
				if (this.$el.data('lang') == 'ru') {
					var re = /^\+7 [(](\d{3})[)] (\d{3})[-](\d{2})[-](\d{2})$/;
					if (!re.test(phone)) {
						this.elem('phone').$el.addClass('empty');
						er = 1;
					}
				}

				// email должен соответствовать маске
				let email = this.elem('email').$el.val();
				var re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}$/;
				if (!re2.test(email)) {
					this.elem('email').$el.addClass('empty');
					er = 1;
				}

				if (er) {
					return;
				}

				// check recaptcha after validate
				global.grecaptcha.execute();
				// let form_data_ = new FormData(element.el);
				let form_data = element.$el.serialize();
				var form = this.elem('form').$el;
				this.$el.find('.b-request-form__text').addClass('hidden');
				$(form).html('<div class="b-preloader"><div class="b-preloader__image"><img src="/images/main_redisign/pages/preloader.png" alt=""></div></div>'); // показать индикатор загрузки

				$.ajax({
					type: 'POST',
					url: '/api/Techart/Feedback/request-form.php',
					data: form_data,
					success: function () {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({'event': 'sendFormRegistr'});
						try {
							window.dataLayer.push({'event': 'formsent','fname': form.data('name')});
							// carrotquest.identify([
							// 	{op:"update_or_create",key:"$name",value: form_data_.get('subscriber_name')},
							// 	{op:"update_or_create",key:"$email",value: form_data_.get('subscriber_email')},
							// 	{op:"update_or_create",key:"$phone",value: form_data_.get('phone').replace('+', '')},
							// 	{op:"update_or_create",key:"Компания",value: form_data_.get('company')},
							// ]);
						} catch(e) {
							return;
						}
						$(form).html('<div class="success">' + $('.success_text').first().text() + '</div>');
					},
				})
			},
			'checkbox-popup.click': function (element, allElements, event) {
				event.preventDefault();
				this.elem('popup').$el.slideToggle('500');
			},
			'checkbox.click': function (element) {
				let checkboxContainer = this.elems('field').byMod('selector')[0];
				if (element.$el.hasClass('connected')) {
					if (checkboxContainer) {
						if (checkboxContainer.hasMod('empty')) {
							checkboxContainer.delMod('empty');
						}
					}
				}

				var blockItems = $('.b-request-form__services-lists');

				if(blockItems.find('.b-request-form__services-item input').is(':checked')) {
					$('.b-request-form__services-item').addClass('pointer-none')

					blockItems.find('.b-request-form__services-item input').each(function () {
						if($(this).is(':checked')) {
							$(this).parent().removeClass('pointer-none');
						}
					})

				} else {
					$('.b-request-form__services-item').removeClass('pointer-none')
				}

			}
		};
	}

	checkCommunicationMethods() {
		let methodList = this.$elem('services-list');
		let methodItems = methodList.find('.b-request-form__checkbox.connected:checkbox:checked');

		return methodItems.length;
	}
}

RequestForm.register();

export default RequestForm;