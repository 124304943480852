import BEM from 'tao-bem';
import Swiper, {Pagination, Scrollbar} from 'swiper';
import PerfectScrollbar from 'perfect-scrollbar';
import 'swiper/css/bundle';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";


class Steps extends BEM.Block {
	static get blockName() {
		return 'b-steps';
	}
	onInit() {
		var it = this;
		var data = this.elem('images').$el.attr('data-bg');
		var wrapper = it.elem('contain').$el;
		var item = it.elems('list-item').$el;
		
			
		$(window).on('load scroll', function() {
			if(it.elem('images').$el.hasClass('disable')) {
				if($(window).scrollTop() > 100 ) {
					it.elem('img').$el.attr('src', data);	
					it.elem('images').$el.removeClass('disable');
				}
			}
		});

		if ($(window).width() > 640) { 
			it.elem('img').$el.attr('src', data);
		}
		

		new Swiper(".stepsSwiper", {
			direction: "horizontal",
			slidesPerView: 1,
			slidesPerGroup: 1,	
			spaceBetween: 0,	
			modules: [Pagination, Scrollbar],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
				dragSize: '110',
			},
			breakpoints: {
				640: {
					slidesPerView: 3,
					scrollbar: {
						dragSize: '180',
					},
					spaceBetween: 0,
				},
				1000: {
					slidesPerView: 3,
					scrollbar: {
						dragSize: '180',
					},
					spaceBetween: 0,
				},
			}
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow41",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__slider`,
						translateX: ["-100px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 800
					});
					anime({
						targets: `.${it.block}__images`,
						translateX: ["100px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 800
					});
				}
			}).init();
		}

		if ($(window).width() > 1000) {
			var height = 0;
			it.elems('list-item').$el.each(function(index) {
				var d = $(this).height();

				if (index < 3) {	
					height = height + d + 32;
					// console.log(height)	
				}
			});
			
			wrapper.css('min-height', height - 32);
		}

		if (item.length > 3) {
			new PerfectScrollbar('.scrollbar', {
				wheelSpeed: 1,
				maxScrollbarLength: 110,
				swipeEasing: false,
			});
		}
	}
}

Steps.register();

export default Steps;