import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class MainText extends BEM.Block {
	static get blockName() {
		return 'b-main-text';
	}

	onInit() {
		const it = this;
		var bg = this.elems('image').$el;
		bg.each( function() {
			var el = $(this).attr('data-bg');
			$(this).css('backgroundImage', `url('${el}')`);
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow31",
				offset: 200,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateX: ["-150px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 800 + 200 * i;
						}
					});
				}
			}).init();
		}
	}
}

MainText.register();

export default MainText;