import BEM from 'tao-bem';
import anime from 'animejs/lib/anime.es.js';
import WOW from "wow.js";

class UsageScenarios extends BEM.Block {
	static get blockName() {
		return 'b-usage-scenarios';
	}

	onInit() {
		var it = this;
		var path = anime.path('.pattern-svg');
		if ($(window).width() > 640) {
			new WOW({
				boxClass: "wow43",
				offset: 0,
				mobile: true,
				live: true,
				callback: function() {
					anime({
						targets: `.${it.block}__container`,
						translateX: ["100px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 600
					});
					anime({
						targets: `.${it.block}__orange-circle`,
						translateX: path('x'),
						translateY: path('y'),
						rotate: path('angle'),
						easing: 'linear',
						duration: 15000,
						loop: true
					});

					anime({
						targets: `.${it.block}__orange-circle`,
						opacity:[0,1],

						delay: 1500,

					});

					anime({
						targets: `.${it.block}__img`,
						scale:[0,1],

						easing: 'linear',
						duration: 1500,

					});
					anime({
						targets: ".pattern-svg__little-circle",
						translateX: {
							value: '-5%',
						},
						translateY: {
							value: '-5%',
						},
						scale: {
							value: 1.1,
						},

						direction: 'alternate',
						easing: 'linear',
						duration: 1500,
						loop: true
					});
				}
			}).init();
		}

		if ($(window).width() > 1000) {
			it.elems('item').$el.hover( function() {
				const image_src = $(this).data('image');

				if(image_src) {
					$(this).css({'backgroundImage' : `url("${image_src}")`});
				}
			}, function() {
				$(this).css({'backgroundImage' : 'none'});
			}
			);
		}

		it.elems('item').$el.click( function() {
			$(this).toggleClass('active');
		});
	}
}

UsageScenarios.register();

export default UsageScenarios;