import BEM from "tao-bem";

class Connection extends BEM.Block {
	static get blockName() {
		return 'b-connection';
	}

	onInit() {
		let widthContainer = $('.b-connection__manager-photo').width();
		$('.b-connection__manager-photo-image').height(widthContainer);

		$(window).resize(function () {
			let widthContainer = $('.b-connection__manager-photo').width();
			$('.b-connection__manager-photo-image').height(widthContainer);

		});
	}
}

Connection.register();

export default Connection;