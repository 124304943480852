import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class ServiceEvents extends BEM.Block {
	static get blockName() {
		return 'b-service_events';
	}
	onInit() {
		const it = this;
		var width = $(window).outerWidth();
		if (width >= 600) {
			$('.b-service_events__image').each(function(){
				var bg = $(this).data('bg');
				if (bg) {
					$(this).css('background-image', "url('" + bg + "')");
				}
			});
		}
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow39",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateX: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 600 + 150 * i;
						}
					});
				}
			}).init();
		}
	}
}

ServiceEvents.register();

export default ServiceEvents;