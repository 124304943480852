import BEM from 'tao-bem';
import 'magnific-popup';

class Main_button extends BEM.Block {
	static get blockName() {
		return 'b-main_button';
	}

	onInit() {
		let buttonUrl = this.elem('link').$el.attr('href');

		if (buttonUrl.search('#') != -1) {
			this.elem('link').$el.magnificPopup({
				type: 'inline',
				showCloseBtn: false,
				fixedContentPos: true,
				callbacks: {
					beforeOpen: function () {
						if($(window).width() <= 768) {
							$('html').addClass('mfp-helper');
						}
						$('.b-request-form__input-btn-name').val($('.b-main_button__link').first().text())
					},
					close: function () {
						$('html').removeClass('mfp-helper');
						$('.b-request-form__field.quetion[id="text"]').addClass('hidden');
					},
				},
			});
		}

		$('.b-main_button--mfp-close').on('click', function () {
			$.magnificPopup.close();
		});
		let hash = window.location.hash;
		if (hash) {
			$('.b-main_button__link[href="'+hash+'"]').click();
		}

		$('.b-main_button__link').on('click', function(){
			var webinar = $(this).data('webinar');
			if (webinar) {
				$('input.webinar_name').attr('value', webinar);
			}
		});
	}
}

Main_button.register();

export default Main_button;