import BEM from "tao-bem";

class Teaser extends BEM.Block {
	static get blockName() {
		return 'b-teaser';
	}

	onInit() {
		var it = this;

		var bg = it.$el.attr('data-bg');
		var hoverBg = it.$el.attr('data-hover-bg');
		var wide = it.$el.hasClass('wide');
		if(!bg) {
			it.$el.addClass('grey');
		}	
		if ($(window).width() > 1440) {
			it.elems('wrapper').$el.mouseover( function(){
				if(hoverBg && !wide) {
					$(this).css('backgroundImage', `url('${hoverBg}')`);
				}
			});
			it.elems('wrapper').$el.mouseleave( function(){
				if(hoverBg && !wide) {
					$(this).css('backgroundImage', `none`);
				}
			});
		}
		
	}
}

Teaser.register();

export default Teaser;