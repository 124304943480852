import BEM from 'tao-bem';
import Swiper, {Pagination} from 'swiper';
import 'swiper/css/bundle';
import 'jquery-match-height';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";
import simpleParallax from 'simple-parallax-js';

class MainBaner extends BEM.Block {
	static get blockName() {
		return 'b-main-baners';
	}

	onInit() {
		// var bg = this.elems('item').$el;
		// bg.each( function() {
		// 	var el = $(this).attr('data-bg');
		// 	$(this).css('backgroundImage', `url('${el}')`);
		// });
		var it = this;
		new Swiper(".baner-slider", {
			modules: [Pagination],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
		});

		if ($(window).width() > 749) {

			new simpleParallax(document.querySelectorAll('.b-main-baners__image'), {
				delay: 0,
				orientation: 'down',
				scale: 1.5,
			});

			new WOW({
				boxClass: "wow25",
				offset: 150,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.b-main-baners__content-wrapper`,
						translateX: ["-60%", "-50%"],
						translateY: ["-50%", "-50%"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 600
					});
				}
			}).init();
		}
	}
}

MainBaner.register();

export default MainBaner;