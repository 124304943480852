import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class BlogArticlesList extends BEM.Block {
	static get blockName() {
		return 'b-blog-articles-list';
	}

	onInit() {
		const it = this;
		var index = 0;

		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow200",
				offset: 150,
				mobile: true,
				live: false,
				callback: function(elem) {
					anime({
						targets: elem,
						translateY: ["-200px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function() {
							return 150 * index++;
						}
					});
					index = 0
				}
			}).init();
		} else {
			it.$el.find('.b-section-top').addClass('active-opacity');
		}
	}
}


BlogArticlesList.register();

export default BlogArticlesList;