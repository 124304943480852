import BEM from "tao-bem";
//import Animate from 'component/animate/animate';
import $ from 'jquery';

class Services extends BEM.Block {
	static get blockName() {
		return 'b-services';
	}

	onInit() {
		window.loading = false;
		bind_scroll_check();

		//Animate.cloudParalax($('.b-services'),$('.b-services__cloud'));
	}
}

function get_filter_items() {
	var items = {
		'SERVICES': [],
		'p_branch': [],
	};

	return items;
}

function bind_scroll_check() {
	// $(window).unbind("scroll wheel mousewheel");
	$(window).on("scroll wheel mousewheel", function() {
		if (!window.loading) {
			scroll_check();
		}
	});
}

function scroll_check() {
	let windowScrool = $(window).scrollTop() + window.innerHeight;
	let bottomPositionLoadBlock = $('.b-services__container').height() + $('.b-services__container').offset().top;
	if (windowScrool >= bottomPositionLoadBlock) {
		// $(window).unbind("scroll wheel mousewheel");
		ajax_load_more();
	}
}

function ajax_load_more() {
	var $experts_list = $('.b-services__list');
	var $nex_page_link = $experts_list.find('.tao-page-navigator a').last();

	if($nex_page_link.length != 0 && !$nex_page_link.hasClass('current')) {
		var myRe = /page=(\d*)/ig;
		var next_page = myRe.exec($nex_page_link.attr('href'));
		next_page = next_page[1];
		window.loading = true;

		$('.ajax-content').append('<div class="b-preloader"><div class="b-preloader__image"><img src="/images/main_redisign/pages/preloader_white.png" alt=""></div></div>'); // показать индикатор загрузки
		var parms = {
			page: next_page,
			filter: get_filter_items(),
		};

		$.get({
			url: '/ajax/saas.php',
			data: parms,
			success: function (data) {
				$('.b-preloader').remove(); // убрать индикатор загрузки
				window.loading = false;
				$experts_list.find('.tao-list').append($(data).siblings('.tao-list').html());
				$experts_list.find('.tao-page-navigator').html($(data).siblings('.tao-page-navigator').html());
				bind_scroll_check();

			},
		});
	}

}

Services.register();

export default Services;