import BEM from 'tao-bem';

class CookieNotification extends BEM.Block {
	static get blockName() {
		return 'b-cookie-notification';
	}

	static get elementsEvents() {
		return {
			'close.click': function () {
				this.$el.slideUp();
				$.cookie(this.$el.data('cookie-name'), true, { expires: 30, path: '/' });
			},

			'button.click': function () {
				this.$el.slideUp();
				$.cookie(this.$el.data('cookie-name'), true, { expires: 30, path: '/' });
			},

		}
	}
}

CookieNotification.register();

export default CookieNotification;