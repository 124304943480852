import BEM from 'tao-bem';
// /* global carrotquest */

class FormEmail extends BEM.Block {
	static get blockName() {
		return 'b-form-email';
	}
	onInit() {
		const it = this;
		const form = $(`.${it.block}__form`);
		const email = $(`.${it.block}__input`);
		const success = $(`.${it.block}__success`);

		form.on('submit', (e) => {
			e.preventDefault();
			global.grecaptcha.execute();
			$.ajax({
				type: 'POST',
				url: '/api/Techart/Feedback/subscribe.php',
				data: {
					email: email.val(),
				},
				success: function () {
					form.addClass('hidden');
					success.addClass('active');
					// carrotquest.track('Подписался на рассылку', {
					// 	"$email": email.val(),
					// });
				}
			});

		})
	}
}

FormEmail.register();

export default FormEmail;