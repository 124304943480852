import BEM from "tao-bem";

class Search extends BEM.Block {
	static get blockName() {
		return 'b-search';
	}
}

Search.register();

export default Search;