import BEM from 'tao-bem';
import Swiper, {Pagination} from 'swiper';
import 'swiper/css/bundle';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class MainWhy extends BEM.Block {
	static get blockName() {
		return 'b-main-why';
	}

	onInit() {
		const it = this;
		let swiper = false;
		if ($(window).width() < 640) {
			swiper = it.SwiperInit(`.whySwiper`);
		}

		window.addEventListener('resize', () => {
			if ($(window).width() < 640) {
				if (!swiper) {
					swiper = it.SwiperInit(`.whySwiper`);
				}
			} else {
				if (swiper) {
					swiper.destroy(true, true);
				}
				swiper = false;
			}
		});
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow32",
				offset: 200,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__item`,
						translateY: ["-150px", 0],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: function(el, i) {
							return 800 + 200 * i;
						}
					});
				}
			}).init();
		}
	}

	SwiperInit(slider_class)
	{
		const it = this;
		return new Swiper(slider_class, {
			modules: [Pagination],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			observer: true,
		});
	}
}

MainWhy.register();

export default MainWhy;