import BEM from 'tao-bem';
//import 'component/magnific-popup';

class ConsoleEnter extends BEM.Block {
	static get blockName() {
		return 'b-console-enter';
	}

	onInit() {
		this.show();
		this.elems('button').byMod('registration').$el.magnificPopup({
			type: 'inline',
			closeBtnInside: false
		});
	}

	show() {
		$.magnificPopup.open({
			type: 'inline',
			showCloseBtn: false,
			items: {
				src: '#console-enter'
			},
			callbacks: {
				close: function () {
					window.location.replace('/');
				}
			}
		});
	}

	static get elementsEvents() {
		return {
			'button.click': function (button, buttons, event) {
				if (button.hasMod('enter')) {
					event.preventDefault();
					window.open(button.$el.attr('href'));
					$.magnificPopup.close();
				}
			}
		}
	}
}

ConsoleEnter.register();

export default ConsoleEnter;