import BEM from "tao-bem";

class TeaserEvent extends BEM.Block {
	static get blockName() {
		return 'b-teaser-event';
	}

	onInit() {
		// 
	}
}

TeaserEvent.register();

export default TeaserEvent;