import BEM from 'tao-bem';

class Pagination extends BEM.Block {
	static get blockName() {
		return 'b-pagination';
	}

	onInit() {
		var page = $('.b-pagination__button').data('page');
		this.elems('item').$el.last().addClass('last');
		var pageLast = parseInt(this.$el.find('.b-pagination__item.last').text());
		if (page == pageLast) {
			$('.b-pagination__button').addClass('hidden');
		} else {
			$('.b-pagination__button').removeClass('hidden');
		}

		$('.b-pagination__button').on('click', function() {
			var it = $(this);
			var count = it.data('count');
			var lang = $(this).data('lang');
			// var next_page = $(this).data('nextpage');
			var next_page;
			if (lang) {
				next_page = `/about/customers/?ajax=1&page=${count}`;
			} else {
				next_page = `/en/about/customers/?ajax=1&page=${count}`;
			}
			
			$.ajax({
				url: next_page,
				cache: false,
				method: 'GET',
				success: function(data) {
					data = $.parseHTML(data);
					if (count == pageLast) {
						$('.b-pagination__button').addClass('hidden');
					}
					it.data('nextpage', `${next_page}`);
					
					$('.tao-list-customers').append(data);
					it.data('count', count+1);
				},
				error: function (err) {
					console.log('Ошибка:'+ err);              
				}
			});
		});
	}
}

Pagination.register();

export default Pagination;