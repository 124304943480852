import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";


class Steps extends BEM.Block {
	static get blockName() {
		return 'b-three_elements_drop_down';
	}
	onInit() {
		const it = this;
		const items = it.$el.find(`.${it.block}__item-desc`);
		const arrows = it.$el.find(`.${it.block}__arrow`);
		arrows.on('click', function () {
			const item = $(this).closest(`.${it.block}__item`);
			item.toggleClass('active')
			if (item.hasClass('active')) {
				item.find(`.${it.block}__item-hidden-desc`).show();
			} else {
				item.find(`.${it.block}__item-hidden-desc`).hide();
			}
		});

		// console.log($(`.${it.block}__desc-image`));

		// $(`.${it.block}__desc-image`).on('mouseover',function () {
		// 	console.log(1);
		// 	$(this).addClass('active');
		// });

		maxHeight()
		$(window).resize(() => {
			maxHeight()
		})

		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow61",
				offset: 150,
				mobile: true,
				live: false,
				callback: function(elem) {
					const item = $(elem).find(`.${it.block}__item`);

					item.each(function(index) {
						anime({
							targets: this,
							translateY: ["-200px", "0"],
							opacity: [0, 1],
							easing: "easeInOutCubic",
							delay: function() {
								return 600 + 150 * index;
							}
						});
					});
				}
			}).init();
		}

		function maxHeight() {
			if ($(window).width() > 749) {
				let maxHeight = 0;
				items.css('height', 'auto');
				items.each(function() {
					const thisHeight = $(this).height();
					if (thisHeight > maxHeight) {
						maxHeight = thisHeight;
					}
				});

				items.css('height', maxHeight)
			}
		}
	}
}

Steps.register();

export default Steps;