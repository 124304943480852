import BEM from 'tao-bem';
import WOW from 'wow.js';
import anime from "animejs/lib/anime.es.js";

class MainDigits extends BEM.Block {
	static get blockName() {
		return 'b-main_digits';
	}

	onInit() {
		// var elems = this.elems('item').$el.length;
		// var wrapper = this.elems('wrapper').$el;
		// wrapper.css('width', `calc(100% / ${elems} - 32px)`);
		const it = this;
		if ($(window).width() > 749) {
			new WOW({
				boxClass: "wow24",
				offset: 0,
				mobile: true,
				live: false,
				callback: function() {
					anime({
						targets: `.${it.block}__wrapper.left`,
						translateX: ["-200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 800
					});
					anime({
						targets: `.${it.block}__wrapper.right`,
						translateX: ["200px", "0"],
						opacity: [0, 1],
						easing: "easeInOutCubic",
						delay: 800
					});

					// anime({
					// 	targets: `.${it.block}__title`,
					// 	translateX: ["-200px", "0"],
					// 	opacity: [0, 1],
					// 	easing: "easeInOutCubic",
					// 	delay: 400
					// });
					// anime({
					// 	targets: `.${it.block}__desc`,
					// 	translateX: ["-200px", "0"],
					// 	opacity: [0, 1],
					// 	easing: "easeInOutCubic",
					// 	delay: 550
					// });
				}
			}).init();
		}
	}
}

MainDigits.register();

export default MainDigits;